
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    DecimalControl,
    IntegerControl,
    TextControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
  },
});
